<template>
  <v-alert :value="show" :type="type" dismissible class="notification-alert" @input="closeAlert">
    {{ message }}
  </v-alert>
</template>

<script>
export default {
  name: 'Notification',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'info'  // Vuetify types: 'success', 'info', 'warning', 'error'
    }
  },
  methods: {
    closeAlert() {
      this.$emit('update:show', false);
    }
  }
};
</script>

<style scoped>
.notification-alert {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;
  width: 300px;
}
</style>
